<template>
    <div id="quoteForm" v-editable="blok" data-test="NewL4QuoteForm">
        <div class="quote-form" :class="{ simple: simple }" :style="bgImage">
            <div class="quote-form__container md:container">
                <!-- Begin: Quote Form - Form -->

                <div v-if="!simple" class="quote-form--header">{{ blok.title }}</div>

                <div class="quote-form__form" :class="{ 'md:order-2': blok.order === 'right' }">
                    <LazyFormTemplateHotel :blok="blok" />
                </div>

                <!-- End: Quote Form - Form -->
                <!-- Begin: Quote Form - Content -->

                <div v-if="!simple" class="quote-form__content">
                    <h1>{{ blok.title }}</h1>
                    <hr />
                    <h2>{{ blok.subtitle }}</h2>
                    <RichText v-if="blok.text.content" :richtext="blok.text" />
                </div>

                <!-- End: Quote Form - Content -->
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4QuoteFormHotels } from '~/types/components/L4QuoteFormHotels';
import screens from '#tailwind-config/theme/screens';

const props = defineProps<{ blok: IL4QuoteFormHotels; simple?: boolean }>();

const bg = computed(() => props.blok.background.filename || '');

if (bg.value) {
    useHead({
        link: [
            {
                rel: 'preload',
                as: 'image',
                href: `${bg.value}/m/0x465/filters:quality(60)`,
                type: 'image/webp',
                media: `(min-width: ${screens.sm}px)`,
            },
            {
                rel: 'preload',
                as: 'image',
                href: `${bg.value}/m/filters:quality(80)`,
                type: 'image/webp',
                media: `(min-width: ${screens.sm}px)`,
            },
        ],
    });
}

const bgImage = computed(() => {
    if (bg.value) {
        return `--hero-bg: url('${bg.value}/m/filters:quality(80)'), url('${bg.value}/m/0x465/filters:quality(60)')`;
    }

    return;
});
</script>

<style lang="postcss" scoped>
#quoteForm {
    @apply bg-primary;
    background: linear-gradient(225deg, #6c3184, #000000);
}

.quote-form {
    @apply min-h-[calc(100vh-4rem)] sm:min-h-0 relative bg-cover md:min-h-0 md:bg-[image:--hero-bg];
    background-position: 0 40%;
    &.simple {
        @apply min-h-0;
        background: none !important;
    }
}
.quote-form__container {
    @apply flex flex-col md:flex-row py-6;
}

.quote-form--header {
    @apply md:hidden mb-4 text-white text-center text-2xl;
}

/* Begin: Quote Form - Form */
.quote-form__form {
    @apply px-4 w-full md:w-[90%];

    & :deep(.input-row .input-group) {
        @apply mr-0;
    }
}

.quote-form__form :deep(input),
.quote-form__form :deep(select) {
    @apply text-lg;
}
/* End: Quote Form - Form */

/* Begin: Quote Form - Content */
.quote-form__content {
    @apply px-4 mt-8 mb-4 md:my-0 w-full text-white;
}
.quote-form__content h1 {
    @apply hidden md:block mb-4 text-white text-3xl;
}
.quote-form__content h2 {
    @apply mb-4 font-bold text-xl;
}
.quote-form__content hr {
    @apply hidden md:block my-4;
}
.quote-form__content :deep(p) {
    @apply text-sm;
}
/* End: Quote Form - Content */
</style>
