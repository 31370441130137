export const useTrafficSplitterStore = defineStore('trafficSplitterStoreV2', {
    state: () => ({
        splitterData: {
            percentages: {},
            timestamp: 0,
        },
    }),
    getters: {
        getTrafficPercentages: (state) => state.splitterData.percentages,
        getTrafficTimestamp: (state) => state.splitterData.timestamp,
    },
    actions: {
        updateSplitterData(data: any) {
            const percentagesFromPinia = { ...this.splitterData.percentages };

            const updatedPercentages = Object.entries(data.percentages).reduce((percentages, [key, value]) => {
                // Flatten percentages object
                const percentagesFromApi = {
                    // @ts-ignore
                    default: value?.default,
                    // @ts-ignore
                    ...value?.dateBasedPercentages,
                };

                if (percentagesFromPinia[key] === undefined) {
                    percentagesFromPinia[key] = {};
                }
                const updatedAirportTraffic = percentagesFromPinia[key];

                // loop through dates percentages
                for (const dateKey in percentagesFromApi) {
                    const dateValue = percentagesFromApi[dateKey];

                    let inMigratedVariant;
                    // for 0 and 100 percentages another logic is used
                    if (dateValue === 0 || dateValue === 100) {
                        // if date is in store, and the migration variant was calculated earlier (can be null) - use it, else - set the migration variant to null for next using
                        if (
                            dateKey in updatedAirportTraffic &&
                            updatedAirportTraffic[dateKey].inMigratedVariant !== undefined
                        ) {
                            inMigratedVariant = updatedAirportTraffic[dateKey].inMigratedVariant;
                        } else {
                            inMigratedVariant = null;
                        }
                    } else {
                        // if date is in store, and the migration variant was calculated earlier and it's not null - use it, else - calculate the migration variant
                        if (
                            dateKey in updatedAirportTraffic &&
                            updatedAirportTraffic[dateKey].inMigratedVariant !== undefined &&
                            updatedAirportTraffic[dateKey].inMigratedVariant !== null
                        ) {
                            inMigratedVariant = updatedAirportTraffic[dateKey].inMigratedVariant;
                        } else {
                            inMigratedVariant = Math.random() < dateValue / 100;
                        }
                    }

                    updatedAirportTraffic[dateKey] = {
                        value: dateValue,
                        inMigratedVariant,
                    };
                }

                // if some date was deleted in Migration service - delete it from Pinia / localStorage
                for (const dateKey in updatedAirportTraffic) {
                    if (!(dateKey in percentagesFromApi)) {
                        delete updatedAirportTraffic[dateKey];
                    }
                }

                percentages[key] = updatedAirportTraffic;
                return percentages;
            }, {});

            this.splitterData = {
                percentages: updatedPercentages,
                timestamp: data.timestamp,
            };
        },
    },
    persist: true,
});
