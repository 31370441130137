import { useTrafficSplitterStore } from '~/store/traffic-splitter';
import { useQuoteStore } from '~/store/quote';
import { getLanguageForAPI, getLanguageForLocale } from '~/utils/getLanguage';

export function useL4Migration() {
    const config = useRuntimeConfig();

    const { MIGRATION_SERVICE_URL, PROPEL_URL } = config.public;

    const isMigrationActive =
        MIGRATION_SERVICE_URL && PROPEL_URL && MIGRATION_SERVICE_URL !== '""' && PROPEL_URL !== '""';

    // Constant variables for access to Pinia stores
    let trafficSplitterStore;

    if (isMigrationActive) {
        trafficSplitterStore = useTrafficSplitterStore();
    }

    // Traffic splitter
    const inMigratedVariant = ref(false);
    const isCheckingkMigration = ref(false);

    const updateTrafficData = async () => {
        try {
            // @ts-ignore
            const response = await $fetch<any>(`${MIGRATION_SERVICE_URL}/site/looking4`);
            // @ts-ignore
            if (response && response.percentages) {
                trafficSplitterStore.updateSplitterData({
                    percentages: response.percentages,
                    timestamp: Date.now(),
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const checkMigrationData = async () => {
        const CHECK_INTERVAL = 1000 * 60 * 5;

        const trafficDataTimestampFromStore = trafficSplitterStore.getTrafficTimestamp;

        if (!trafficDataTimestampFromStore || Date.now() - trafficDataTimestampFromStore > CHECK_INTERVAL) {
            await updateTrafficData();
        }
    };

    const checkMigrationVariant = async (code = '', toDate) => {
        if (!code || !toDate) return;

        isCheckingkMigration.value = true;
        await checkMigrationData();
        isCheckingkMigration.value = false;

        // Create key for airport to sync with migration service
        const trafficKey = `${code?.toLowerCase()}_parking-only`;

        const percentagesFromPinia = trafficSplitterStore.getTrafficPercentages;

        const isAirportInMigration = percentagesFromPinia[trafficKey];

        if (!isAirportInMigration) {
            inMigratedVariant.value = false;
        } else {
            const toDateFormatted = new Date(toDate).toISOString().slice(0, 10);

            const dayStartFrom = findDatePosition(percentagesFromPinia[trafficKey], toDateFormatted);
            const resultedPercentage = percentagesFromPinia[trafficKey][dayStartFrom].value;

            if (resultedPercentage === 0) {
                inMigratedVariant.value = false;
            } else if (resultedPercentage === 100) {
                inMigratedVariant.value = true;
            } else {
                inMigratedVariant.value = percentagesFromPinia[trafficKey][dayStartFrom].inMigratedVariant;
            }
        }
    };

    const findDatePosition = (dateValues, inputDate) => {
        const datesCopy = { ...dateValues };
        // remove default value for sorting only dates
        delete datesCopy.default;
        // for format "YYYY-MM-DD", the normal JS sort() function works correctly
        const datesSorted = Object.keys(datesCopy).sort();

        if (inputDate < datesSorted[0] || datesSorted.length === 0) {
            return 'default';
        }

        for (let i = 0; i < datesSorted.length - 1; i++) {
            if (inputDate >= datesSorted[i] && inputDate < datesSorted[i + 1]) {
                return datesSorted[i];
            }
        }

        return datesSorted[datesSorted.length - 1];
    };

    return { isMigrationActive, checkMigrationVariant, redirectToPropel, inMigratedVariant, isCheckingkMigration };
}

export function redirectToPropel(location, discountValue, propelProductSKU, countryLangPropelUrl = '') {
    const quoteStore = useQuoteStore();
    const route: any = useRoute();
    const config = useRuntimeConfig();

    const formatDateToPropel = (date: Date) => new Date(date).toISOString().substring(0, 10);
    const entryDate = formatDateToPropel(quoteStore.getAllData.fromDate);
    const entryTime = quoteStore.getAllData.fromTime;
    const exitDate = formatDateToPropel(quoteStore.getAllData.toDate);
    const exitTime = quoteStore.getAllData.toTime;
    const code = location.value.code.toUpperCase();
    const discount = discountValue.value ? `&discount=${discountValue.value}` : '';
    const product = propelProductSKU ? `&product=${propelProductSKU}` : '';
    const propelUrl = countryLangPropelUrl ? countryLangPropelUrl : config?.public.PROPEL_URL;
    const groups = '%7B"adult"%3A1%7D'; // default 1 adult - {adult:1}

    const query = route.query;
    let marketingChannels = '';
    if ('utm_source' in query) {
        marketingChannels += `&utmSource=${query['utm_source']}`;
    }
    if ('utm_medium' in query) {
        marketingChannels += `&utmMedium=${query['utm_medium']}`;
    }
    if ('clickref' in query) {
        marketingChannels += `&clickref=${query['clickref']}`;
    }

    window.location.href = `${propelUrl}search/?entryDate=${entryDate}&entryTime=${entryTime}&exitDate=${exitDate}&exitTime=${exitTime}&airport=${code}&terminal=dontKnow&groups=${groups}${discount}${product}${marketingChannels}`;
}

export async function useL4PropelUrl() {
    const { locale } = useI18n();
    const country: string = getLanguageForAPI(locale.value);
    const language: string = getLanguageForLocale(locale.value);
    const config: any = useRuntimeConfig();

    const propelPartners: any = await useAsyncStoryblok(`l4/global/l4propelpartners`, {
        version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
        language: getLanguageForLocale(locale.value),
    }).then((data) => {
        return data.value?.content?.Partners;
    });

    const currentPropelUrl = computed(
        () =>
            propelPartners?.find((propelPartner) => {
                return propelPartner.Country === country && propelPartner.Language === language;
            })?.PropelUrl || ''
    );

    return { currentPropelUrl };
}
