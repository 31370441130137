<template>
    <form :action="formAction" method="post" @submit.prevent="saveToStore">
        <input name="Itinerary.Campaign" :value="route.query.campaign ?? ''" type="hidden" />
        <input name="Quote_Itinerary_Location_" type="hidden" :value="location?.code" />
        <input name="Itinerary.LocationCode" :value="location?.code" type="hidden" />
        <input
            name="Quote.Itinerary.Dates.From.Date_local"
            :value="new Date(quoteStore.getAllData.fromDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input
            name="Itinerary.Dates.From.Date"
            :value="new Date(quoteStore.getAllData.fromDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input name="Itinerary.Dates.From.Time" :value="quoteStore.getAllData.fromTime" type="hidden" />
        <input
            name="Itinerary.Dates.To.Date"
            :value="new Date(quoteStore.getAllData.toDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input name="Itinerary.Dates.To.Time" :value="quoteStore.getAllData.toTime" type="hidden" />
        <input name="Itinerary.DiscountCode" :value="discountValue" type="hidden" />
        <input name="Itinerary.VelocityMemberNumber" :value="velocityInput" type="hidden" />
        <fieldset>
            <!-- Begin: Quote Form - Form - Airport Search -->
            <SearchInput
                icon="ship-fill"
                name="search"
                :placeholder="t('quoteForm.search')"
                :items="groupedItems"
                :value="location.name"
                :validation-errors-count="validationErrorsCount"
                @update:value="(value: any) => updateLocation(value)"
            />
            <!-- End: Quote Form - Form - Airport Search -->

            <!-- Begin: Quote Form - Form - From Date/Time -->
            <div class="input-columns-2">
                <div class="input-row">
                    <DateInput
                        icon="calendar-2-line"
                        name="from"
                        :title="t('quoteForm.fromDateTitle')"
                        :min-date="currentDate()"
                        :value="quoteStore.getAllData.fromDate ? new Date(quoteStore.getAllData.fromDate) : null"
                        direction="from"
                        :placeholder="t('quoteForm.date')"
                        @update:value="
                            (value: any) => quoteStore.updateQuoteFormFrom(value, quoteStore.getAllData.fromTime)
                        "
                    />

                    <TimeInput
                        icon="time-line"
                        name="from"
                        help-icon="info"
                        :help-title="t('quoteForm.port.fromTimeTooltip')"
                        :value="quoteStore.getAllData.fromTime"
                        @update:value="
                            (value: any) => quoteStore.updateQuoteFormFrom(quoteStore.getAllData.fromDate, value)
                        "
                    />
                </div>
            </div>
            <!-- End: Quote Form - Form - From Date/Time -->

            <!-- Begin: Quote Form - Form - To Date/Time -->
            <div class="input-columns-2">
                <div class="input-row">
                    <DateInput
                        icon="calendar-2-line"
                        name="to"
                        :title="t('quoteForm.toDateTitle')"
                        :min-date="quoteStore.getAllData.fromDate ? quoteStore.getAllData.fromDate : currentDate()"
                        :value="quoteStore.getAllData.toDate ? new Date(quoteStore.getAllData.toDate) : null"
                        direction="to"
                        :placeholder="t('quoteForm.date')"
                        @update:value="(value: any) => updateToDate(value)"
                    />

                    <TimeInput
                        icon="time-line"
                        name="to"
                        help-icon="info"
                        :help-title="t('quoteForm.port.toTimeTooltip')"
                        :value="quoteStore.getAllData.toTime"
                        :min-time="minToTime"
                        @update:value="
                            (value: any) => quoteStore.updateQuoteFormTo(quoteStore.getAllData.toDate, value)
                        "
                    />
                </div>
            </div>
            <!-- End: Quote Form - Form - To Date/Time -->

            <!-- Begin: Quote Form - Form - Coupon -->
            <div v-show="blok.show_discount" class="input-group">
                <NuxtIcon name="ri:coupon-2-line" class="input-icon-prepend" />
                <input
                    id="discountInput"
                    type="text"
                    name="discount"
                    :disabled="!isMounted"
                    :placeholder="t('quoteForm.discount')"
                    :value="discountValue"
                    @input="(event: any) => updateDiscount(event)"
                />
            </div>
            <!-- End: Quote Form - Form - Coupon -->

            <!-- Begin: Quote Form - Form - Velocity -->
            <div v-if="blok.show_velocity" class="input-group">
                <img src="~/assets/images/velocity-logo.png" alt="Velocity" class="velocity-icon" />
                <input
                    id="velocityInput"
                    v-model="velocityInput"
                    name="velocity"
                    type="text"
                    :disabled="!isMounted"
                    :placeholder="t('quoteForm.velocity')"
                    data-test="new-velocity-input"
                />
            </div>
            <!-- End: Quote Form - Form - Velocity -->

            <ComparePrices
                :type-compare="location.code ? 'submit' : 'button'"
                class-compare="submit right-icon text-lg font-semibold"
                :is-disabled="isCheckingkMigration"
                @click="checkPort"
            />
        </fieldset>
    </form>
</template>

<script setup lang="ts">
import { useQuoteStore } from '~/store/quote';
import SearchInput from '~/components/Form/SearchInput.vue';
import DateInput from '~/components/Form/DateInput.vue';
import TimeInput from '~/components/Form/TimeInput.vue';
import type { IL4QuoteForm } from '~/types/components/L4QuoteForm';
import { differenceInDays } from '~/utils/helpers';
import { useL4Migration, useL4PropelUrl, redirectToPropel } from '~/composables/L4PropelMigration';

const { blok } = defineProps<{ blok: IL4QuoteForm }>();

// Propel migration
const propelProductSKU = inject<string>('propelProductSKU', '');
const { isMigrationActive, inMigratedVariant, isCheckingkMigration, checkMigrationVariant } = useL4Migration();

// Const variable for access to runtime config
const config: any = useRuntimeConfig();

// Constant variable for access to route information
const route: any = useRoute();

// Constant variable for access to quote store
const quoteStore = useQuoteStore();

// Constant Variable for access to locale & translations
const { t, locale } = useI18n();

// Reference to Velocity input
const velocityInput: any = ref('');

const isMounted = ref(false);

if (route.query.code) {
    quoteStore.updateQuoteFormDiscount(route.query.code);
}

const today = ref(new Date());

const discountChanged = ref(false);

const updateDiscount = (event) => {
    discountChanged.value = true;
    quoteStore.updateQuoteFormDiscount(event.target.value);
};

const discountValue = computed(() =>
    quoteStore.getAllData.discount === '' && !discountChanged.value
        ? blok.default_discount
        : quoteStore.getAllData.discount
);

// Reference to selectedLocation which is updated by FormSearch component
const location: any = ref({
    name: blok.airport?.name,
    code: blok.airport?.content ? blok.airport?.content?.code?.toUpperCase() : blok.airport?.code?.toUpperCase(),
});

// Computed variable for the form action. Will update based on selectedLocation.
const formAction = computed(() =>
    location.value.code
        ? `/${locale.value}/${t('pages.port')}/${t('pages.search-results')}/${location.value?.code?.toLowerCase()}`
        : ''
);
const language: any = getLanguageForLocale(locale.value);
let groupedItems: unknown[] = [];
const airportDropdownList: any = await useAsyncStoryblok(`l4/global/dropdowns/ports`, {
    version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
    language: getLanguageForLocale(locale.value),
    resolve_relations: ['AirportList.country'],
}).then((data) => {
    const airports = [];
    data.value?.content?.List.map((countryAirports) => {
        countryAirports.List.map((airport) => {
            airports.push({
                code: airport.code,
                name: airport.name,
                country: {
                    slug: countryAirports.country?.content.code,
                    name: countryAirports.country?.content.name,
                },
            });
        });
    });

    return airports.filter(
        (item, index, array) => index === array.findIndex((findItem) => findItem.code === item.code)
    );
});

let filteredResults = airportDropdownList;

if (config?.public?.STORYBLOK_VERSION === 'published') {
    filteredResults = filteredResults.filter((item) => {
        return item.code !== 'PTE';
    });
}

// Re-arrange the areas to be a child of their countries
groupedItems = Object.values(groupItemBy([...new Set(filteredResults)], 'country.slug')).sort((arr1, arr2) =>
    arr1[0].country.name.localeCompare(arr2[0].country.name)
);
// Validation
const validationErrorsCount = ref(0);

const checkPort = () => {
    if (!location.value.code) {
        validationErrorsCount.value++;
    }
};

const updateLocation = (value) => {
    location.value = value;
    validationErrorsCount.value = 0;
    if (isMigrationActive) {
        checkMigrationVariant(location.value.code, quoteStore.getAllData.toDate);
    }
};

const updateToDate = (value) => {
    quoteStore.updateQuoteFormTo(value, quoteStore.getAllData.toTime);
    if (isMigrationActive) {
        checkMigrationVariant(location.value.code, quoteStore.getAllData.toDate);
    }
};

const { currentPropelUrl } = await useL4PropelUrl();

onMounted(() => {
    isMounted.value = true;
    if (isMigrationActive) {
        checkMigrationVariant(location.value.code, quoteStore.getAllData.toDate);
    }
});

// Save to store only after submitting form to search results, for reusing after 'go back' action
// When a user navigates through Nuxt pages, Pinia doesn't update.
const saveToStore = (e) => {
    quoteStore.updateQuoteFormStartDestination({ code: location.value.code, name: location.value.name });
    if (!isMigrationActive) {
        e.target.submit();
    } else {
        inMigratedVariant.value
            ? redirectToPropel(location, discountValue, propelProductSKU, currentPropelUrl.value)
            : e.target.submit();
    }
};

//in case if user open the page and leave it to the next day, the date should be updated
const currentDate = () => {
    if (differenceInDays(today.value, new Date()) > 0) {
        today.value = new Date();
    }

    return today.value;
};

const minToTime = computed(() => {
    const minDate = new Date(new Date(quoteStore.getAllData.fromDate).toDateString());
    const toDate = new Date(new Date(quoteStore.getAllData.toDate).toDateString());
    if (differenceInDays(minDate, toDate) > 0) {
        return '';
    }

    return quoteStore.getAllData.fromTime;
});
</script>
