export function fixContentItemLinks(content: any) {
    content.forEach((item: any) => {
        if (item?.content) {
            fixContentItemLinks(item.content);
        }

        if (item?.marks) {
            // process the marks array
            item.marks.forEach((mark) => {
                if (mark.type === 'link' && mark.attrs?.linktype === 'story') {
                    mark.attrs.href = mark.attrs.href.replace(/\/\/+/g, '/').replace('/l4/pages', '');
                }
            });
        }
    });
}
