<template>
    <div class="input-group">
        <button :type="typeCompare" :class="classCompare" :disabled="isDisabled" class="disabled:opacity-75">
            <slot>
                {{ t('quoteForm.buttonCompare') }}
            </slot>
        </button>
    </div>
</template>

<script setup lang="ts">
defineProps({
    typeCompare: { type: String, default: null },
    classCompare: { type: String, default: null },
    isDisabled: { type: Boolean, default: false },
});

// Constant Variable for access to locale & translations
const { t } = useI18n();
</script>

<style lang="postcss" scoped>
.comparePrices-style {
    @apply text-3xl font-bold rounded-md border border-solid border-bodypurple h-12 flex justify-center items-center;
}
.comparePrices-sm {
    @apply text-base font-bold rounded-md border border-solid border-bodypurple h-7 flex justify-center items-center;
}
</style>
