<template>
    <div
        id="quoteForm"
        v-editable="blok"
        data-test="NewL4QuoteForm"
        :class="{ 'mb-16 md:mb-24': blok.with_bottom_margin }"
    >
        <div class="airport-hero" :style="bgImage">
            <div class="airport-hero__container container">
                <div class="airport-hero__text-wrapper">
                    <div class="airport-hero__text">
                        <h1 class="airport-hero__title">{{ blok.title }}</h1>
                        <h2 class="airport-hero__descr">
                            <RichText :richtext="blok.description" />
                        </h2>
                    </div>
                </div>

                <div class="airport-hero__form">
                    <div v-if="blok.logo?.filename" class="airport-hero__form-logo">
                        <NuxtImg
                            v-if="blok.logo?.filename"
                            :src="blok.logo.filename"
                            :title="blok.logo.title"
                            :alt="blok.logo.alt"
                            provider="storyblok"
                            format="webp"
                            loading="lazy"
                        />
                    </div>
                    <div v-if="blok.form_title" class="airport-hero__form-title">
                        <p>{{ blok.form_title }}</p>
                    </div>
                    <FormTemplateTransfers v-if="blok.type === 'transfers'" :blok="blok" />
                    <FormTemplatePort v-else-if="blok.type === 'port'" :blok="blok" />
                    <FormTemplateRailway v-else-if="blok.type === 'train'" :blok="blok" />
                    <FormTemplateParking v-else :blok="blok" new-button-style />
                </div>

                <div v-if="blok.keysellingpoints" class="airport-hero__points">
                    <div
                        v-for="point in blok.keysellingpoints"
                        :key="point._uid"
                        class="airport-hero__point"
                        :class="{ green: blok.GreenMarkers }"
                    >
                        <RichText class="airport-hero__point-text" :richtext="point.content" />
                    </div>
                </div>

                <div class="airport-hero__review">
                    <button @click="scrollToReviews">
                        <NuxtImg
                            v-if="imageUrl"
                            :src="imageUrl"
                            title="See what our customers say about us"
                            alt="Feefo logo"
                            format="webp"
                            loading="lazy"
                            densities="x1"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4QuoteFormExtended } from '~/types/components/L4QuoteFormExtended';
import screens from '#tailwind-config/theme/screens';

const { blok } = defineProps<{ blok: IL4QuoteFormExtended }>();

const bg = computed(() => blok.background.filename || '');

// FEEFO STARS
const { imageUrl, reviewsUrl } = useFeefo();

if (bg.value) {
    useHead({
        link: [
            {
                rel: 'preload',
                as: 'image',
                href: `${bg.value}/m/0x465/filters:quality(60)`,
                type: 'image/webp',
                media: `(min-width: ${screens.sm}px)`,
            },
            {
                rel: 'preload',
                as: 'image',
                href: `${bg.value}/m/filters:quality(80)`,
                type: 'image/webp',
                media: `(min-width: ${screens.sm}px)`,
            },
        ],
    });
}

const bgImage = computed(() => {
    if (bg.value) {
        return `--hero-bg: url('${bg.value}/m/filters:quality(80)'), url('${bg.value}/m/0x465/filters:quality(60)')`;
    }

    return;
});

function scrollToReviews() {
    const header = document.querySelector('header');
    const headerHeight = header?.getBoundingClientRect().height;
    const customerReviews = document.getElementById('customerReviews');
    const customerReviewsTop = customerReviews?.getBoundingClientRect().top;
    if (headerHeight && customerReviewsTop) {
        window.scrollTo({
            top: customerReviewsTop - headerHeight,
            behavior: 'smooth',
        });
    } else if (import.meta.client && reviewsUrl) {
        window.open(reviewsUrl);
    }
}
</script>

<style lang="postcss" scoped>
#quoteForm {
    @apply bg-primary;
    background: linear-gradient(225deg, #6c3184, #000000);
}

.airport-hero {
    @apply relative py-6 md:pt-[42px] md:pb-[42px] bg-cover bg-center min-h-[760px] sm:min-h-0 md:bg-[image:--hero-bg];

    .hero-image {
        @apply absolute top-0 bottom-0 left-0 right-0 w-full h-full object-cover z-[0];
    }

    &__container {
        @apply relative z-[1] sm:grid gap-x-8 px-4 grid-cols-2;
    }
    &__form {
        @apply row-span-4 col-start-2 mb-3 sm:mb-0 flex flex-col justify-center;
        :deep(input),
        :deep(select) {
            @apply text-lg;
        }
    }
    &__form-logo {
        @apply mb-4 md:mb-3;
        img {
            @apply h-10 object-contain object-center;
        }
    }
    &__form-title {
        @apply mb-4 md:mb-3 text-white text-xl leading-5;
    }
    &__text-wrapper {
        @apply mb-4 sm:mb-0 row-span-1;
    }
    &__text {
        max-width: 454px;
    }
    &__title {
        @apply text-white font-semibold text-[24px] sm:text-[32px] sm:leading-10 mb-4 sm:mb-6;
    }
    &__descr {
        @apply mb-4 md:mb-6 text-white;
        :deep(div) {
            p {
                @apply text-xl sm:text-2xl;
            }
        }
    }
    &__points {
        @apply mb-3 md:mb-6 row-span-1;
        :deep(div) {
            p {
                @apply my-0;
                font-size: inherit;
            }
        }
    }
    &__point {
        @apply relative flex items-center;
        &:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14' fill='none'%3E%3Cpath d='M17.5 1.41L5.5 13.41L0 7.91L1.41 6.5L5.5 10.58L16.09 0L17.5 1.41Z' fill='%23EBEBEB'/%3E%3C/svg%3E");
            @apply inline-block mr-3 shrink-0 h-[14px] w-[18px] relative top-[-4px];
        }
        &:not(:last-child) {
            @apply mb-1.5;
        }
        &.green {
            &:before {
                content: url('~/assets/images/green-marker.png');
                @apply h-[14px] w-[18px];
            }
        }
    }
    &__point-text {
        @apply text-white font-normal text-xs;
        :deep(b),
        :deep(strong) {
            @apply text-white font-semibold text-base;
        }
    }
    &__review {
        @apply row-span-1;
        button,
        img {
            @apply block;
        }
    }
}
</style>
