<!-- eslint-disable vue/no-v-html -->
<template>
    <div>
        <div class="rich-text simple-richtext" v-html="parsedContent" />
    </div>
</template>

<script setup lang="ts">
const props: any = defineProps({ richtext: { type: Object, default: null } });
import { fixContentItemLinks } from '~/utils/fixRichTextLinks';

// Storyblok's function for rendering rich text
// https://www.storyblok?.com/faq/how-to-render-richtext-nuxt
const parsedContent: any = computed(() => {
    //copy object because we need to modify it
    const richTextObj = JSON.parse(JSON.stringify(props.richtext));
    fixContentItemLinks(richTextObj.content);

    return renderRichText(richTextObj);
});
</script>

<style scoped>
:deep(ol) {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
</style>
