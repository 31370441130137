const languages: any = {
    en: ['uk', 'en', 'us', 'au', 'ca', 'ie', 'nz', 'za', 'hu', 'gr', 'ru'],
    fr: ['fr', 'be', 'ca-fr', 'ch-fr'],
    es: ['es', 'cl', 'mx', 'ar'],
    it: ['it'],
    de: ['de', 'at', 'ch'],
    nl: ['nl', 'be-nl'],
    pl: ['pl'],
    pt: ['pt', 'br'],
};

export const paths: any = {
    parking: {
        en: 'airport-parking',
        es: 'aparcamiento-en-el-aeropuerto',
        fr: 'parking-a-aeroport',
        de: 'flughafen-parkplatze',
        pt: 'estacionamento-do-aeroporto',
        it: 'parcheggio-aeroporto',
        nl: 'parkeren-bij-de-luchthaven',
        pl: 'parking-lotnisko',
        jp: 'airport-parking',
    },
    'port-parking': {
        en: 'port-parking',
        es: 'estacionamiento-del-puerto',
        fr: 'parking-du-port',
        de: '',
        pt: '',
        it: 'parcheggio-porto',
        nl: 'poort-parkeren',
        pl: '',
        jp: '',
    },
    transfers: {
        en: 'airport-transfers',
        es: 'traslado-aeroporto',
        fr: 'transferts-aeroports',
        de: 'flughafen-transfer',
        pt: 'transfers-para-o-aeroporto',
        it: 'transfer-aeroportuali',
        nl: 'luchthaventransfers',
        pl: 'lotniskowe-transfery',
        jp: 'transfers',
    },
    'hotel-with-parking': {
        en: 'airport-hotels-and-parking',
        es: '',
        fr: '',
        de: '',
        pt: '',
        it: '',
        nl: '',
        pl: '',
        jp: '',
    },
    'train-station-parking': {
        en: 'train-station-parking',
        es: 'aparcamiento-en-la-estacion-de-tren',
        fr: '',
        de: '',
        pt: 'trainstation-parking',
        it: 'parcheggio-stazione-treni',
        nl: '',
        pl: '',
        jp: '',
    },
};

export function getLanguageForAPI(lang: string) {
    if (lang) {
        let language = '';
        switch (lang) {
            case 'uk':
                language = 'uk';
                break;
            case 'ca-fr':
                language = 'ca';
                break;
            case 'ch-fr':
                language = 'ca';
                break;
            case 'be-nl':
                language = 'be';
                break;
            default:
                language = lang;
        }
        return language;
    }
}

export function getLanguageForLocale(lang: string | string[] | undefined) {
    if (lang) {
        let language = '';

        (Object.keys(languages) as (keyof typeof languages)[]).forEach((key: any) => {
            if (languages[key].includes(lang)) {
                language = key;
            }
        });

        return language;
    }
}

export function getLanguageForStoryblok(lang: string) {
    if (lang) {
        let language = '';
        switch (lang) {
            case 'uk':
                language = 'en';
                break;
            case 'ca-fr':
                language = 'fr';
                break;
            case 'ch-fr':
                language = 'fr';
                break;
            case 'be-nl':
                language = 'nl';
                break;
            default:
                language = lang;
        }
        return language;
    }
}

export function getProductTypeSlug(lang: string | undefined, type: string) {
    const typeI18n: { [key: string]: { [key: string]: string } } = {
        en: {
            'airport-parking': 'airport-parking',
            'port-parking': 'port-parking',
            'airport-transfers': 'airport-transfers',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
        de: {
            'airport-parking': 'flughafen-parkplatze',
            'port-parking': 'port-parking',
            'airport-transfers': 'flughafen-transfer',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
        pl: {
            'airport-parking': 'parking-lotnisko',
            'port-parking': 'port-parking',
            'airport-transfers': 'lotniskowe-transfery',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
        fr: {
            'airport-parking': 'parking-a-aeroport',
            'port-parking': 'port-parking',
            'airport-transfers': 'transferts-aeroports',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
        it: {
            'airport-parking': 'parcheggio-aeroporto',
            'port-parking': 'parcheggio-porto',
            'airport-transfers': 'transfer-aeroportuali',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
        pt: {
            'airport-parking': 'estacionamento-do-aeroporto',
            'port-parking': 'estacionamiento-del-puerto',
            'airport-transfers': 'transfers-para-o-aeroporto',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
        es: {
            'airport-parking': 'aparcamiento-en-el-aeropuerto',
            'port-parking': 'estacionamiento-del-puerto',
            'airport-transfers': 'traslado-aeroporto',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
        nl: {
            'airport-parking': 'parkeren-bij-de-luchthaven',
            'port-parking': 'port-parking',
            'airport-transfers': 'airport-transfers',
            'airport-hotels-and-parking': 'airport-hotels-and-parking',
            'all-parking': 'all-parking',
        },
    };

    let page = '';
    (Object.keys(languages) as (keyof typeof languages)[]).forEach((key) => {
        if (languages[key].includes(lang)) {
            page = typeI18n[key as any][type];
        }
    });

    return page;
}
