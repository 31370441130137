<!-- eslint-disable vue/no-v-html -->
<template>
    <label :for="`${name}TimeInput`" class="input-group">
        <NuxtIcon :name="`ri:${icon}`" class="input-icon-prepend" />

        <select :id="`${name}TimeInput`" v-model="timeValue" :name="`${name} time`" :disabled="!isMounted">
            <!-- isMounted variable in key attr is needed for correct render disabled state after component mount -->
            <template v-for="timeOption in timeOptions" :key="`${timeOption.label}-${isMounted}`">
                <option
                    :value="timeOption.label"
                    :selected="timeOption.label === '12:00'"
                    :disabled="isDisabledOption(timeOption.value)"
                >
                    {{ timeOption.value }}
                </option>
            </template>
        </select>

        <div class="tooltip">
            <NuxtIcon
                v-if="helpText || helpTitle"
                :name="`ri:${helpIcon}`"
                class="input-icon-append"
                :data-title="helpTitle"
                data-text="helpText"
            />
            <div class="tooltip-hover">
                <div class="tooltip-title" v-html="helpTitle" />
                <div class="tooltip-text" v-html="helpText" />
            </div>
        </div>
    </label>
</template>

<script setup lang="ts">
const props: any = defineProps({
    name: { type: String, required: false, default: 'time' },
    placeholder: { type: String, required: false, default: 'Select Time' },
    value: { type: String, required: false, default: '12:00' },
    minTime: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    itemList: { type: Array, required: false, default: null },
    helpTitle: { type: String, required: false, default: '' },
    helpText: { type: String, required: false, default: '' },
    helpIcon: { type: String, required: false, default: '' },
});
// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

const defaultTime = ref(props.value);

const isMounted = ref(false);

watch(
    () => props.value,
    (newVal) => {
        defaultTime.value = newVal;
    }
);

// Whenever new time is set, we want to update the variable and emit the data
// to parent for adding to store.
const timeValue: any = computed({
    get() {
        return defaultTime.value;
    },
    set(value) {
        emit('update:value', value);
    },
});

const isDisabledOption = (timeOption: string): boolean => {
    if (!props.minTime) {
        return false;
    }

    const baseDate = `${new Date().getFullYear()}-01-01`;
    const dateTime1 = new Date(`${baseDate} ${timeOption}:00`);
    const dateTime2 = new Date(`${baseDate} ${props.minTime}:00`);

    // Compare the two date-time objects
    return dateTime1 < dateTime2;
};

interface ISelectOption {
    label: string;
    value: string;
}

const timeOptions = computed(() => {
    const startTime = new Date(Date.UTC(0, 0, 1, 24, 0));
    const endTime = new Date(Date.UTC(0, 0, 2, 23, 59));
    const timeStops: ISelectOption[] = [];

    while (startTime <= endTime) {
        const time = new Date(startTime);
        const hhmm =
            time.getUTCHours().toString().padStart(2, '0') + ':' + time.getUTCMinutes().toString().padStart(2, '0');

        timeStops.push({ label: hhmm, value: hhmm } as ISelectOption);
        startTime.setUTCMinutes(startTime.getUTCMinutes() + 15);
    }

    return timeStops;
});

onMounted(() => {
    isMounted.value = true;
});
</script>
<style lang="postcss" scoped>
select[disabled] {
    @apply opacity-100;
}
option[disabled] {
    @apply text-[#ccc];
}
</style>
