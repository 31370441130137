<template>
    <div v-on-click-outside.bubble="() => (showDropdown = false)" class="input-group" @focusin="openDropdown">
        <NuxtIcon v-if="icon" :name="`ri:${icon}`" class="input-icon-prepend" />
        <!-- @vue-ignore -->
        <input
            :id="`${name}Input`"
            :class="{ 'is-error': validationErrorsCount !== 0 }"
            :value="inputValue"
            :name="name"
            type="search"
            :placeholder="placeholder"
            autocomplete="off"
            @input="(e) => (inputValue = e.target.value)"
            @focus="(e) => (inputValue = '')"
        />
        <ul v-if="showDropdown" class="input-dropdown" :class="{ left: direction === 'to' }">
            <li v-if="loading && inputValue" class="input-dropdown-heading">Searching ...</li>
            <template v-for="item in itemArray" :key="item.NameClass">
                <li v-if="item" class="input-dropdown-heading">
                    {{ item.Name }}
                </li>
                <template v-for="childItem in item.Children" :key="childItem.Code">
                    <li class="input-dropdown-item" @click="selectItem(childItem)">
                        {{ childItem.Name }}
                        <span>[{{ childItem.Type }}]</span>
                    </li>
                </template>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
// VueUse - onClickOutside
// https://vueuse.org/core/onClickOutside/
import { vOnClickOutside } from '@vueuse/components';
import { useQuoteStore } from '~/store/quote';

const props = defineProps({
    name: { type: String, required: true, default: 'search' },
    placeholder: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    direction: { type: String, required: true, default: 'from' },
    from: { type: Object, required: false, default: null },
    validationErrorsCount: { type: Number, default: 0 },
});

// Constant variable for access to route information
const route = useRoute();

const selectItem = (e) => {
    inputValue.value = e.Name;
    selected.value = e;
    emit('update:value', e);
    closeDropdown();
};

// Constant Variable for access to locale & translations
const { locale } = useI18n();

// Constant variable to define prop 'items'
const itemArray: any = ref();

// Track if we're loading results.
const loading = ref(false);

// Constant variable to define prop 'value'
const inputValue: any = ref(props.value);

// Toggle to show/hide dropdown
const showDropdown = ref(false);

// Initialising emit events
const emit: any = defineEmits(['update:value']);

const selected = ref(null);
const searchRequest = useDebounceFn(async (request) => {
    // https://www.looking4.com/uk/airport-transfers/search-locations-from/{value} ==> props.direction === 'from'
    // https://www.looking4.com/uk/airport-transfers/search-locations-to/{from}-{value/'airport'} ==> props.direction === 'to'
    const result: any = await $fetch(
        `/${process.dev ? 'proxy/' : ''}${locale.value}/${route?.params?.type}/search-locations-${props.direction}/${
            props.direction === 'to'
                ? props.from.Code + '-' + props.from.Type + '/' + request?.toLowerCase()
                : request?.toLowerCase()
        }`,
        {
            method: 'GET',
        }
    );
    loading.value = false;
    itemArray.value = result;
}, 400);

// Watch when inputValue is changed, we want to fetch eachtime an new input is placed.
// We also want to enable and disable results when loaded and replace the itemsArray.
// We do it here instead of openDropdown for API optimisation.
watch(
    () => inputValue.value,
    async (val) => {
        loading.value = true;
        if (val) {
            await searchRequest(val);
        }
    }
);

// We want to clear input and itemArray when 'from' property changes.
watch(
    () => props.from,
    () => {
        if (props.direction === 'to') {
            inputValue.value = '';
            itemArray.value = null;
        }
    },
    { deep: true }
);

// Event to close dropdown
const closeDropdown = () => {
    showDropdown.value = false;
};

// Event to open dropdown
const openDropdown = () => {
    showDropdown.value = true;
    searchRequest(inputValue.value);
};

// Validation
watch(
    () => props.validationErrorsCount,
    (count) => {
        showDropdown.value = count !== 0;
        if (count !== 0) {
            searchRequest(inputValue.value);
        }
    }
);

// Get search value from localStorage, works only if the user has previously visited the search results page and then navigated back.
// Pinia store clears for correct airport / port / transfer filling in single entity page
onMounted(() => {
    const quoteStore = useQuoteStore();
    const transfer = quoteStore.getAllData[props.direction === 'from' ? 'startDestination' : 'endDestination'];
    if (transfer.code) {
        selected.value = { Code: transfer.code, Name: transfer.name };
        quoteStore[props.direction === 'from' ? 'updateQuoteFormStartDestination' : 'updateQuoteFormEndDestination']({
            code: '',
            name: '',
        });
    }
});
</script>

<style lang="postcss" scoped>
.input-dropdown {
    @apply absolute left-0 right-[-108%] z-[99] top-[2.75rem] bg-white rounded max-h-[300px] overflow-y-auto;
}

.input-dropdown.left {
    @apply left-[-108%] right-0;
}

.input-dropdown-heading {
    @apply flex items-center justify-between p-2 bg-primary border-b border-primary text-white;
}

.input-dropdown-item {
    @apply flex items-center justify-between p-2 border-b border-[#e9daf0] text-gray-600;
}

.input-dropdown-heading {
    @apply font-semibold;
}

.input-dropdown-item span {
    @apply text-xs;
}

.input-dropdown-item:hover {
    @apply bg-primary text-white cursor-pointer;
}

.input-group input.is-error {
    &::placeholder {
        color: #ff0000;
    }
    border: 1px solid #ff0000;
    &:focus {
        @apply border-primary;
    }
}
</style>
