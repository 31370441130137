export const differenceInDays = (date1: Date, date2: Date) => {
    // Convert both dates to milliseconds
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();

    // Calculate the difference in milliseconds
    const differenceMs = date2Ms - date1Ms;

    // Convert back to days and return
    return Math.floor(differenceMs / (1000 * 60 * 60 * 24));
};

export const differenceInDaysWithoutTime = (date1: Date, date2: Date) => {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return differenceInDays(date1, date2);
};

export const nextTimeSlot = (currentTime: string, minuteInterval: number) => {
    const [hour, minute] = currentTime.split(':').map(Number);

    // Calculate the next minute slot
    const nextMinute =
        (minute % minuteInterval === 0 ? minute / minuteInterval + 1 : Math.ceil(minute / minuteInterval)) *
        minuteInterval;

    if (nextMinute === 60) {
        // Move to the next hour
        const nextHour = (hour + 1) % 24;
        const formattedHour = nextHour.toString().padStart(2, '0');
        return `${formattedHour}:00`;
    }

    const formattedMinute = nextMinute.toString().padStart(2, '0');
    const formattedHour = hour.toString().padStart(2, '0');
    return `${formattedHour}:${formattedMinute}`;
};

export const isEndDateBeforeStartDate = (startDateValue, endDateValue) => {
    const startDate = new Date(startDateValue.date);
    const startTime = startDateValue.time.split(':');
    startDate.setHours(parseInt(startTime[0]));
    startDate.setMinutes(parseInt(startTime[1]));

    const endDate = new Date(endDateValue.date);
    const endTime = endDateValue.time.split(':');
    endDate.setHours(parseInt(endTime[0]));
    endDate.setMinutes(parseInt(endTime[1]));

    return endDate > startDate;
};

export const addDays = (date: Date | string, daysToAdd: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + daysToAdd);
    return result;
};
