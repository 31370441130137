<template>
    <div v-on-click-outside="() => (showDropdown = false)" class="input-group" @focusin="() => (showDropdown = true)">
        <NuxtIcon name="ri:user-line" class="input-icon-prepend" />

        <input
            :id="`${name}Input`"
            type="text"
            :name="name"
            class="cursor-pointer"
            :value="`${t('quoteForm.transfers.passengers')}: ${passengers.total}`"
            readonly
        />

        <div v-if="showDropdown" class="input-dropdown">
            <div class="passenger__list">
                <div class="passenger__list--item">
                    <span>{{ t('quoteForm.transfers.adults') }}</span>
                    <input
                        :value="passengers.adult"
                        type="number"
                        max="99"
                        min="1"
                        step="1"
                        @input="(event: any) => adjustPassengers('adult', event.target.value)"
                    />
                    <div class="buttons-container">
                        <button type="button" class="plus" @click="adjustPassengers('adult', passengers.adult + 1)">
                            +
                        </button>
                        <button type="button" class="minus" @click="adjustPassengers('adult', passengers.adult - 1)">
                            -
                        </button>
                    </div>
                </div>
                <div class="passenger__list--item">
                    <span>{{ t('quoteForm.transfers.children') }}</span>
                    <input
                        :value="passengers.children"
                        type="number"
                        max="99"
                        min="0"
                        @input="(event: any) => adjustPassengers('children', event.target.value)"
                    />
                    <div class="buttons-container">
                        <button
                            type="button"
                            class="plus"
                            @click="adjustPassengers('children', passengers.children + 1)"
                        >
                            +
                        </button>
                        <button
                            type="button"
                            class="minus"
                            @click="adjustPassengers('children', passengers.children - 1)"
                        >
                            -
                        </button>
                    </div>
                </div>
                <div class="passenger__list--item">
                    <span>{{ t('quoteForm.transfers.infants') }}</span>
                    <input
                        :value="passengers.infants"
                        type="number"
                        max="99"
                        min="0"
                        @input="(event: any) => adjustPassengers('infants', event.target.value)"
                    />
                    <div class="buttons-container">
                        <button type="button" class="plus" @click="adjustPassengers('infants', passengers.infants + 1)">
                            +
                        </button>
                        <button
                            type="button"
                            class="minus"
                            @click="adjustPassengers('infants', passengers.infants - 1)"
                        >
                            -
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// VueUse - onClickOutside
// https://vueuse.org/core/onClickOutside/
import { vOnClickOutside } from '@vueuse/components';
import { useQuoteStore } from '~/store/quote';

// Constant variable for access to quote store
const quoteStore = useQuoteStore();

// Constant Variable for access to locale & translations
const { t } = useI18n();

defineProps({
    name: { type: String, required: true, default: 'passengers' },
    value: {
        type: Object,
        required: false,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: { adult: 1, children: 0, infants: 0, total: 1 },
    },
    icon: { type: String, required: false, default: '' },
});

// Constant variable for initialising emit events
defineEmits(['update:value']);

// Toggle to show/hide calendar.
const showDropdown = ref(false);

const passengers: any = ref(quoteStore.getAllData.passengers);

function adjustPassengers(variable: string, value: number) {
    if (value < 100 && value >= (variable === 'adult' ? 1 : 0)) {
        passengers.value[variable] = value;
        passengers.value.total =
            parseInt(passengers.value.adult) + parseInt(passengers.value.children) + parseInt(passengers.value.infants);
        quoteStore.updateQuoteFormPassengers(passengers.value);
    }
}
</script>

<style lang="postcss" scoped>
.input-dropdown {
    @apply absolute left-0 z-[99] top-12 bg-white border border-primary rounded max-h-[300px];
}

.passenger__list {
    @apply pt-4 px-4;
    &--item {
        @apply flex items-center justify-end mb-4 font-semibold whitespace-nowrap;
        & span {
            @apply text-[#666];
        }
        & input {
            @apply w-10 h-8 px-2 ml-4 mb-0 border border-primary font-normal appearance-none;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        & .buttons-container {
            @apply flex pl-4;
            & button {
                line-height: 0;
                @apply flex items-center justify-center h-8 w-8 m-0 border border-primary text-xl;
                &.plus {
                    @apply rounded-l bg-primary text-white;
                }
                &.minus {
                    @apply pb-[3px] rounded-r border-l-transparent text-primary;
                }
            }
        }
    }
}
</style>
