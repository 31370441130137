export function useFeefo() {
    const { locale } = useI18n();
    const langConverted: string | undefined = getLanguageForLocale(locale.value);

    let merchantId: string;

    switch (langConverted) {
        case 'fr':
            merchantId = 'looking4parking-france';
            break;
        case 'de':
            merchantId = 'looking4parking-germany';
            break;
        case 'it':
            merchantId = 'looking4parking-italy';
            break;
        case 'pt':
            merchantId = 'looking4parking-portugal';
            break;
        case 'es':
            merchantId = 'looking4parking-spain';
            break;
        default:
            merchantId = 'looking4parking-uk';
    }

    const url = `https://api.feefo.com/api/javascript/${merchantId}`;
    const imageUrl = `https://api.feefo.com/api/logo?merchantidentifier=${merchantId}`;
    const reviewsUrl = `https://www.feefo.com/reviews/${merchantId}`;

    return { url, imageUrl, reviewsUrl, feefoMerchantId: merchantId };
}
