<template>
    <div
        id="quoteForm"
        v-editable="blok"
        data-test="NewL4QuoteForm"
        :class="{ 'mb-16 md:mb-24': blok.with_bottom_margin, simple: simple }"
    >
        <div class="quote-form" :class="{ simple: simple }" :style="bgImage">
            <div class="quote-form__container md:container">
                <!-- Begin: Quote Form - Form -->

                <div v-if="!simple" class="quote-form--header">{{ blok.title }}</div>

                <div class="quote-form__form" :class="{ 'order-2': blok.order === 'right' }">
                    <LazyFormTemplateTransfers v-if="blok.type === 'transfers'" :blok="blok" />
                    <LazyFormTemplatePort v-else-if="blok.type === 'port'" :blok="blok" />
                    <LazyFormTemplateRailway v-else-if="blok.type === 'train'" :blok="blok" />
                    <LazyFormTemplateParking v-else :blok="blok" />
                </div>

                <!-- End: Quote Form - Form -->
                <!-- Begin: Quote Form - Content -->

                <div v-if="!simple" class="quote-form__content">
                    <h1>{{ blok.title }}</h1>
                    <hr />
                    <h2>{{ blok.subtitle }}</h2>
                    <p>{{ blok.text }}</p>

                    <nuxt-link v-if="blok.show_review" :to="reviewsUrl" external class="quote-form__review">
                        <NuxtImg
                            v-if="imageUrl"
                            :src="imageUrl"
                            title="See what our customers say about us"
                            alt="Feefo logo"
                            format="webp"
                            loading="lazy"
                        />
                    </nuxt-link>
                </div>

                <!-- End: Quote Form - Content -->
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4QuoteForm } from '~/types/components/L4QuoteForm';
import screens from '#tailwind-config/theme/screens';

const { blok } = defineProps<{ blok: IL4QuoteForm; simple?: boolean }>();

const bg = computed(() => blok.background.filename || '');

if (bg.value) {
    useHead({
        link: [
            {
                rel: 'preload',
                as: 'image',
                href: `${bg.value}/m/0x465/filters:quality(60)`,
                type: 'image/webp',
                media: `(min-width: ${screens.sm}px)`,
            },
            {
                rel: 'preload',
                as: 'image',
                href: `${bg.value}/m/filters:quality(80)`,
                type: 'image/webp',
                media: `(min-width: ${screens.sm}px)`,
            },
        ],
    });
}

const bgImage = computed(() => {
    if (bg.value) {
        return `--hero-bg: url('${bg.value}/m/filters:quality(80)'), url('${bg.value}/m/0x465/filters:quality(60)')`;
    }

    return;
});

// FEEFO STARS
const { imageUrl, reviewsUrl } = useFeefo();
</script>

<style lang="postcss" scoped>
#quoteForm {
    @apply bg-primary;
    background: linear-gradient(225deg, #6c3184, #000000);

    &.simple {
        background: none !important;
    }
}

.quote-form {
    background-position: 0 50%;
    background: linear-gradient(225deg, #6c3184, #000000);
    @apply relative bg-cover min-h-[760px] sm:min-h-0 md:bg-[image:--hero-bg];

    &.simple {
        background: none !important;
        @apply min-h-0;
    }

    .hero-image {
        @apply absolute top-0 bottom-0 left-0 right-0 w-full h-full object-cover object-[0] z-[0];
    }
}
.quote-form__container {
    @apply relative flex flex-col md:flex-row py-6 z-[2];
}

.quote-form--header {
    @apply md:hidden mb-4 px-4 lg:px-0 text-white text-center text-2xl;
}

/* Begin: Quote Form - Form */
.quote-form__form {
    @apply px-4 w-full;
}

.quote-form__form :deep(input),
.quote-form__form :deep(select) {
    @apply text-lg;
}
/* End: Quote Form - Form */

/* Begin: Quote Form - Content */
.quote-form__content {
    @apply px-4 mt-8 mb-4 md:my-0 w-full text-white text-center md:text-left;
}
.quote-form__content h1 {
    @apply hidden md:block mb-4 text-white text-3xl;
}
.quote-form__content h2 {
    @apply mb-4 font-bold text-xl;
}
.quote-form__content hr {
    @apply hidden md:block my-4;
}
.quote-form__review {
    @apply block mt-5;
    img {
        @apply block;
    }
}
/* End: Quote Form - Content */
</style>
