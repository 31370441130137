<template>
    <form :action="formAction" method="POST" @submit.prevent="submitForm">
        <input name="Itinerary.Campaign" :value="route.query.campaign ?? ''" type="hidden" />
        <input name="Quote_Itinerary_Location_" type="hidden" :value="location?.code" />
        <input name="Itinerary.LocationCode" :value="location?.code" type="hidden" />
        <input
            name="Quote.Itinerary.Dates.From.Date_local"
            :value="new Date(quoteStore.getAllData.fromDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input
            name="Itinerary.Dates.From.Date"
            :value="new Date(quoteStore.getAllData.fromDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input name="Itinerary.Dates.From.Time" :value="quoteStore.getAllData.fromTime" type="hidden" />
        <input
            name="Itinerary.Dates.To.Date"
            :value="new Date(quoteStore.getAllData.toDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input name="Itinerary.Dates.To.Time" :value="quoteStore.getAllData.toTime" type="hidden" />
        <input name="Itinerary.DiscountCode" :value="discountValue" type="hidden" />
        <fieldset>
            <!-- Begin: Quote Form - Form - Airport Search -->
            <SearchInput
                icon="train-line"
                name="search"
                :placeholder="t('quoteForm.railways.search')"
                :items="groupedItems"
                :value="location.name"
                :validation-errors-count="validationErrorsCount"
                @update:value="(value: any) => updateLocation(value)"
            />
            <!-- End: Quote Form - Form - Airport Search -->

            <!-- Begin: Quote Form - Form - From Date/Time -->
            <div class="input-columns-2">
                <div class="input-row">
                    <DateInput
                        icon="calendar-2-line"
                        name="from"
                        direction="from"
                        :title="t('quoteForm.fromDateTitle')"
                        :min-date="currentDate()"
                        :value="quoteStore.getAllData.fromDate ? quoteStore.getAllData.fromDate : null"
                        :placeholder="t('quoteForm.date')"
                        @update:value="
                            (value: any) => quoteStore.updateQuoteFormFrom(value, quoteStore.getAllData.fromTime)
                        "
                    />

                    <TimeInput
                        icon="time-line"
                        name="from"
                        help-icon="info"
                        :help-title="t('quoteForm.railways.fromTimeTooltip')"
                        :value="quoteStore.getAllData.fromTime"
                        @update:value="
                            (value: any) => quoteStore.updateQuoteFormFrom(quoteStore.getAllData.fromDate, value)
                        "
                    />
                </div>
            </div>
            <!-- End: Quote Form - Form - From Date/Time -->

            <!-- Begin: Quote Form - Form - To Date/Time -->
            <div class="input-columns-2">
                <div class="input-row">
                    <DateInput
                        icon="calendar-2-line"
                        name="to"
                        direction="to"
                        :title="t('quoteForm.toDateTitle')"
                        :min-date="quoteStore.getAllData.fromDate ? quoteStore.getAllData.fromDate : currentDate()"
                        :value="quoteStore.getAllData.toDate ? quoteStore.getAllData.toDate : null"
                        :placeholder="t('quoteForm.date')"
                        @update:value="
                            (value: any) => {
                                quoteStore.updateQuoteFormTo(value, quoteStore.getAllData.toTime);
                            }
                        "
                    />

                    <TimeInput
                        icon="time-line"
                        name="to"
                        help-icon="info"
                        :help-title="t('quoteForm.railways.toTimeTooltip')"
                        :min-time="minToTime"
                        :value="quoteStore.getAllData.toTime"
                        @update:value="
                            (value: any) => quoteStore.updateQuoteFormTo(quoteStore.getAllData.toDate, value)
                        "
                    />
                </div>
            </div>
            <!-- End: Quote Form - Form - To Date/Time -->

            <!-- Begin: Quote Form - Form - Coupon -->
            <div v-show="blok.show_discount" class="input-group">
                <NuxtIcon name="ri:coupon-2-line" class="input-icon-prepend" />
                <input
                    id="discountInput"
                    type="text"
                    name="discount"
                    :disabled="!isMounted"
                    :placeholder="t('quoteForm.discount')"
                    :value="discountValue"
                    @input="(event: any) => updateDiscount(event)"
                />
            </div>
            <!-- End: Quote Form - Form - Coupon -->

            <ComparePrices
                :type-compare="location.code ? 'submit' : 'button'"
                :class-compare="compareClasses"
                @click="checkLocation"
            >
                {{ t('quoteForm.railways.buttonCompare') }}
            </ComparePrices>
        </fieldset>
    </form>
</template>

<script setup lang="ts">
import { useQuoteStore } from '~/store/quote';
import SearchInput from '~/components/Form/SearchInput.vue';
import DateInput from '~/components/Form/DateInput.vue';
import TimeInput from '~/components/Form/TimeInput.vue';
import type { IL4QuoteForm } from '~/types/components/L4QuoteForm';
import type { IL4QuoteFormExtended } from '~/types/components/L4QuoteFormExtended';
import { differenceInDays } from '~/utils/helpers';

const { blok, newButtonStyle } = defineProps<{ blok: IL4QuoteForm | IL4QuoteFormExtended; newButtonStyle?: false }>();

// Const variable for access to runtime config
const config: any = useRuntimeConfig();

// Constant variable for access to route information
const route: any = useRoute();

// Constant variables for access to Pinia stores
const quoteStore = useQuoteStore();

// Constant Variable for access to locale & translations
const { t, locales, locale } = useI18n();

const isMounted = ref(false);

const localeObj: any = Object.values(locales.value).find((item: any) => item.code === locale.value);
const today = ref(new Date());

if (route.query.code) {
    quoteStore.updateQuoteFormDiscount(route.query.code);
}

const discountChanged = ref(false);

const updateDiscount = (event) => {
    discountChanged.value = true;
    quoteStore.updateQuoteFormDiscount(event.target.value);
};

const discountValue = computed(() =>
    quoteStore.getAllData.discount === '' && !discountChanged.value
        ? blok.default_discount
        : quoteStore.getAllData.discount
);

// Reference to selectedLocation which is updated by FormSearch component
const location: any = ref({
    name: blok.airport.name,
    code: blok.airport.content ? blok.airport?.content?.code?.toUpperCase() : blok.airport?.code?.toUpperCase(),
});

// Computed variable for the form action. Will update based on selectedLocation.
const formAction = computed(() =>
    location.value.code
        ? `/${locale.value}/${t('pages.train-station-parking')}/${t('pages.search-results')}/${location.value?.code?.toLowerCase()}`
        : ''
);

const airportDropdownList: any = await useAsyncStoryblok(`l4/global/dropdowns/railways`, {
    version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
    language: getLanguageForLocale(locale.value),
    resolve_relations: ['AirportList.country'],
}).then((data) => {
    const stations = [];
    data.value?.content?.List.map((countryStations) => {
        countryStations.List.map((station) => {
            stations.push({
                code: station.code,
                name: station.name,
                country: {
                    slug: countryStations.country?.content.code,
                    name: countryStations.country?.content.name,
                },
            });
        });
    });

    return stations.filter(
        (item, index, array) => index === array.findIndex((findItem) => findItem.code === item.code)
    );
});

const groupedItems: any = Object.values(groupItemBy([...new Set(airportDropdownList)], 'country.slug')).sort(
    (arr1, arr2) => arr1[0].country.name.localeCompare(arr2[0].country.name)
);

const compareClasses = computed(() =>
    newButtonStyle ? 'submit text-lg font-bold green' : 'submit right-icon text-lg font-semibold'
);

// Validation
const validationErrorsCount = ref(0);

const checkLocation = () => {
    if (!location.value.code) {
        validationErrorsCount.value++;
    }
};

const updateLocation = (value) => {
    location.value = value;
    validationErrorsCount.value = 0;
};

// Save to store only after submitting form to search results, for reusing after 'go back' action
// When a user navigates through Nuxt pages, Pinia doesn't update.
const submitForm = (e) => {
    quoteStore.updateQuoteFormStartDestination({
        code: location.value.code,
        name: location.value.name,
    });
    e.target.submit();
};

//in case if user open the page and leave it to the next day, the date should be updated
const currentDate = () => {
    if (differenceInDays(today.value, new Date()) > 0) {
        today.value = new Date();
    }

    return today.value;
};

const minToTime = computed(() => {
    const minDate = new Date(new Date(quoteStore.getAllData.fromDate).toDateString());
    const toDate = new Date(new Date(quoteStore.getAllData.toDate).toDateString());
    if (differenceInDays(minDate, toDate) > 0) {
        return '';
    }

    return quoteStore.getAllData.fromTime;
});

onMounted(() => {
    isMounted.value = true;
});
</script>
