import type { IL4Url } from '~/types/components/L4Links';

export function groupBy(array: any[], key: number): { [string: string]: [] } {
    return array.reduce((rv: any, x: string[]) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export const groupItemBy = (array: any[], property: string) => {
    const hash: { [key: string]: any } = {};
    const props = property.split('.');
    for (let i = 0; i < array.length; i++) {
        const key: string = props.reduce((acc, prop) => {
            return acc && acc[prop];
        }, array[i]);
        if (!hash[key]) hash[key] = [];
        hash[key].push(array[i]);
    }
    return hash;
};

export function chunkArray(array: any[], by: number) {
    return array.reduce((acc: unknown[], _: number, i: number) => {
        if (!(i % by)) {
            acc.push(array.slice(i, i + by));
        }
        return acc;
    }, []);
}

export function snakeToPascal(string: string) {
    return string
        .split('/')
        .map((snake) =>
            snake
                .split('_')
                .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
                .join('')
        )
        .join('/');
}

export const convertToCamelCase = (str: string) =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const convertToKebabCase = (str: string) =>
    str
        .replace(/[.*+?^${}()|[\]\\]/g, '')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase();

export const capitalizeString = (str: string) =>
    typeof str !== 'string' ? '' : str.charAt(0).toUpperCase() + str.slice(1);

export const clearUrl = (str: string) => (typeof str !== 'string' ? '' : str.replace(/(^\w+:|^)\/\//, ''));

export function getCookie(name: string): string | null {
    const cookie = document.cookie.split(';').find((entry) => {
        return entry.trim().includes(name);
    });

    return cookie ? cookie.split('=')[1] : null;
}

export function setCookie(name: string, value: string, time: number): void {
    let expires = '';
    const yearsEQ = 24 * 60 * 60 * 1000;
    if (time) {
        const date = new Date();
        date.setTime(date.getTime() + time * yearsEQ);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
}

export function deleteCookie(name: string): void {
    document.cookie = `${name}=; Max-Age=-99999999;`;
}

export interface ScriptOptions {
    force?: boolean;
    async?: boolean;
    defer?: boolean;
}

export function injectScript(src: string, options: ScriptOptions = {}): Promise<void> {
    return new Promise((resolve) => {
        if (options.force || !Array.from(document.getElementsByTagName('script')).find((el) => el.src === src)) {
            const script = document.createElement('script');

            script.src = src;
            script.type = 'text/javascript';
            script.async = !!options.async;
            script.defer = !!options.defer;
            script.onload = () => resolve();

            document.body.appendChild(script);
        } else {
            resolve();
        }
    });
}

/*
 * Get Image
 * --------
 * Function to get different image sizes, quality and file type from Storyblok Image API.
 * https://www.storyblok?.com/docs/image-service
 * url: The Storyblok image url.
 * size: The desired height of the image. (width will adjust automatically using image ratio)
 * quality: The quality of the image as a percentage
 * optimize: Boolean, whether we use webp or not.
 */
export function getImage(url: any, size: any, quality = 100) {
    const imgSize = size + 'x0';
    const imgFormat = '/m';
    const imgQuality = `:quality(${quality})`;

    if (url && typeof url === 'string') {
        // We don't apply optimisations when SVG as it should already be optimial and isn't supported
        // in storyblok's image API.
        if (url.includes('.svg')) {
            return url;
        }

        return url + imgFormat + '/' + (size ? imgSize + '/' : '') + 'filters' + imgQuality;
    }
}

export const getL4InternalLink = (linkObject: IL4Url) => {
    if (!linkObject) return;
    // @ts-ignore
    const domain: string = useRuntimeConfig().public?.SITE_URL || '';
    const link: string = linkObject.url || linkObject.cached_url || '';
    if (linkObject.linktype === 'url') {
        if (link?.startsWith('https://') || link?.startsWith('http://')) {
            return link;
        }

        const pattern = /^([a-z]{2})(-[a-z]{2})?$/;
        if (!pattern.test(linkObject.url.split('/')[1])) {
            return domain + link;
        }

        const path = linkObject.url.replace('/' + linkObject.url.split('/')[1], '').replace(domain, '');
        const localePath = useLocalePath();

        return domain + localePath(path);
    }

    const splitPaths = link.split('l4/pages/');
    //compare with 1 in case if the url contains / at the beginning of url
    const hasPrefixLang = splitPaths.length > 1 && splitPaths[0].length > 1;
    if (hasPrefixLang) {
        const lang = splitPaths[0].replaceAll('/', '');
        const location = splitPaths[1].split('/')[0];
        return `${domain}/${location}-${lang}/${splitPaths[1].replace(location + '/', '')}`;
    }

    return link.replace('l4/pages/', domain + '/');
};

export const differenceInDays = (date1: Date, date2: Date) => {
    // Convert both dates to milliseconds
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
};
