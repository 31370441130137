<template>
    <form :action="formAction" method="post" @submit.prevent="saveToStore">
        <input name="Itinerary.Campaign" :value="route.query.campaign ?? ''" type="hidden" />
        <input
            id="Quote_Itinerary_SourceLocation_Code_"
            name="Quote.Itinerary.SourceLocation.Code_"
            type="hidden"
            :value="from?.Name"
        />
        <input
            id="Itinerary_SourceLocation_Code"
            name="Itinerary.SourceLocation.Code"
            type="hidden"
            :value="from?.Code"
        />
        <input
            id="Quote.Itinerary_TargetLocation_Code_"
            name="Quote.Itinerary.TargetLocation.Code_"
            type="hidden"
            :value="to?.Name"
        />
        <input
            id="Itinerary_TargetLocation_Code"
            name="Itinerary.TargetLocation.Code"
            type="hidden"
            :value="to?.Code"
        />
        <input
            name="Quote.Itinerary.OutboundDate.Date"
            :value="new Date(quoteStore.getAllData.fromDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input
            name="Itinerary.OutboundDate.Date"
            :value="new Date(quoteStore.getAllData.fromDate).toISOString().split('T')[0]"
            type="hidden"
        />
        <input name="Itinerary.OutboundDate.Time" :value="quoteStore.getAllData.fromTime" type="hidden" />
        <template v-if="tripReturn">
            <input
                name="Quote.Itinerary.ReturnDate.Date"
                :value="new Date(quoteStore.getAllData.toDate).toISOString().split('T')[0]"
                type="hidden"
            />
            <input
                name="Itinerary.ReturnDate.Date"
                :value="new Date(quoteStore.getAllData.toDate).toISOString().split('T')[0]"
                type="hidden"
            />
            <input name="Itinerary.ReturnDate.Time" :value="quoteStore.getAllData.toTime" type="hidden" />
        </template>

        <input name="Itinerary.Passengers.Adults" :value="quoteStore.getAllData.passengers.adult" type="hidden" />
        <input name="Itinerary.Passengers.Children" :value="quoteStore.getAllData.passengers.children" type="hidden" />
        <input name="Itinerary.Passengers.Infants" :value="quoteStore.getAllData.passengers.infants" type="hidden" />
        <input
            id="Quote_Itinerary_Passengers"
            name="Passengers_"
            :value="quoteStore.getAllData.passengers.total"
            type="hidden"
        />
        <input name="Itinerary.Discount.Code" :value="discountValue" type="hidden" />
        <fieldset>
            <div class="input-group">
                <button
                    id="returnInput"
                    name="return"
                    type="button"
                    class="button"
                    :disabled="!isMounted"
                    :class="{ active: tripReturn }"
                    @click="toggleTrip(true)"
                >
                    {{ t('quoteForm.transfers.return') }}
                </button>
                <button
                    id="singleInput"
                    name="single"
                    type="button"
                    class="button"
                    :disabled="!isMounted"
                    :class="{ active: !tripReturn }"
                    @click="toggleTrip(false)"
                >
                    {{ t('quoteForm.transfers.single') }}
                </button>
            </div>

            <div class="input-columns-2">
                <div class="input-row">
                    <!-- Begin: Quote Form - Form - Transfers Start From Search -->
                    <FormSearchTransfers
                        :placeholder="t('quoteForm.transfers.fromSearch')"
                        name="searchfrom"
                        direction="from"
                        :value="from.Name"
                        :validation-errors-count="validationErrorsCountFrom"
                        @update:value="(value: any) => updateLocationFrom(value)"
                    />
                    <!-- End: Quote Form - Form -  Transfers Start From Search -->

                    <!-- Begin: Quote Form - Form - Transfers Going To Search -->
                    <FormSearchTransfers
                        :placeholder="t('quoteForm.transfers.toSearch')"
                        name="searchTo"
                        direction="to"
                        :value="to.Name"
                        :from="from"
                        :validation-errors-count="validationErrorsCountTo"
                        @update:value="(value: any) => updateLocationTo(value)"
                    />
                    <!-- End: Quote Form - Form -  Transfers Going To Search -->
                </div>
            </div>

            <!-- Begin: Quote Form - Form - From Date/Time -->
            <div class="input-columns-2">
                <div class="input-row">
                    <DateInput
                        icon="calendar-2-line"
                        name="from"
                        :title="t('quoteForm.transfers.fromDateTitle')"
                        :min-date="currentDate()"
                        :value="quoteStore.getAllData.fromDate ? quoteStore.getAllData.fromDate : null"
                        direction="from"
                        @update:value="
                            (value: any) =>
                                value != quoteStore.getAllData.fromDate
                                    ? quoteStore.updateQuoteFormFrom(value, quoteStore.getAllData.fromTime)
                                    : false
                        "
                    />

                    <TimeInput
                        icon="time-line"
                        name="from"
                        help-icon="info"
                        :value="quoteStore.getAllData.fromTime"
                        @update:value="
                            (value: any) =>
                                value != quoteStore.getAllData.fromTime
                                    ? quoteStore.updateQuoteFormFrom(quoteStore.getAllData.fromDate, value)
                                    : false
                        "
                    />
                </div>
            </div>
            <!-- End: Quote Form - Form - From Date/Time -->

            <!-- Begin: Quote Form - Form - To Date/Time -->
            <div v-if="tripReturn" class="input-columns-2">
                <div class="input-row">
                    <DateInput
                        icon="calendar-2-line"
                        name="to"
                        :title="t('quoteForm.transfers.toDateTitle')"
                        :min-date="quoteStore.getAllData.fromDate ? quoteStore.getAllData.fromDate : currentDate()"
                        :value="quoteStore.getAllData.toDate ? new Date(quoteStore.getAllData.toDate) : null"
                        direction="to"
                        placeholder="Select Date"
                        @update:value="
                            (value: any) => {
                                value != quoteStore.getAllData.toDate
                                    ? quoteStore.updateQuoteFormTo(value, quoteStore.getAllData.toTime)
                                    : false;
                            }
                        "
                    />

                    <TimeInput
                        icon="time-line"
                        name="to"
                        help-icon="info"
                        :min-time="minToTime"
                        :value="quoteStore.getAllData.toTime"
                        @update:value="
                            (value: any) =>
                                value != quoteStore.getAllData.toTime
                                    ? quoteStore.updateQuoteFormTo(quoteStore.getAllData.toDate, value)
                                    : false
                        "
                    />
                </div>
            </div>
            <!-- End: Quote Form - Form - To Date/Time -->

            <div class="input-columns-2">
                <div class="input-row">
                    <PassengersInput
                        name="passengers"
                        @input="(event: any) => quoteStore.updateQuoteFormPassengers(event.target.value)"
                    />
                    <!-- Begin: Quote Form - Form - Coupon -->
                    <div v-show="blok?.show_discount" class="input-group">
                        <NuxtIcon name="ri:coupon-2-line" class="input-icon-prepend" />
                        <input
                            id="discountInput"
                            type="text"
                            name="discount"
                            :disabled="!isMounted"
                            :placeholder="t('quoteForm.discount')"
                            :value="discountValue"
                            @input="(event: any) => updateDiscount(event)"
                        />
                    </div>
                    <!-- End: Quote Form - Form - Coupon -->
                </div>
            </div>

            <div class="input-group">
                <button
                    :type="to.Code && from.Code ? 'submit' : 'button'"
                    class="submit right-icon text-lg font-semibold"
                    @click="checkTransfer"
                >
                    {{ t('quoteForm.buttonSearch') }}
                </button>
            </div>
        </fieldset>
    </form>
</template>

<script setup lang="ts">
import { useQuoteStore } from '~/store/quote';
import DateInput from '~/components/Form/DateInput.vue';
import TimeInput from '~/components/Form/TimeInput.vue';
import PassengersInput from '~/components/Form/PassengersInput.vue';
import type { IL4QuoteForm } from '~/types/components/L4QuoteForm';
import { differenceInDays } from '~/utils/helpers';

const { blok } = defineProps<{ blok: IL4QuoteForm }>();

// Const variable for access to runtime config
const config: any = useRuntimeConfig();

// Constant variable for access to route information
const route: any = useRoute();

// Constant variable for access to quote store
const quoteStore = useQuoteStore();

// Constant Variable for access to locale & translations
const { t, locale } = useI18n();
const today = ref(new Date());

const isMounted = ref(false);

// Trip return variable
const tripReturn = ref(true);

const toggleTrip = (e: boolean) => {
    tripReturn.value = e;
};

if (route.query.code) {
    quoteStore.updateQuoteFormDiscount(route.query.code);
}

const discountChanged = ref(false);

const updateDiscount = (event) => {
    discountChanged.value = true;
    quoteStore.updateQuoteFormDiscount(event.target.value);
};

const discountValue = computed(() =>
    quoteStore.getAllData.discount === '' && !discountChanged.value
        ? blok.default_discount
        : quoteStore.getAllData.discount
);

// Reference to selectedLocation which is updated by FormSearch component
const fromCode = blok.airport?.content ? blok.airport?.content?.code?.toUpperCase() : blok.airport?.code?.toUpperCase();
const fromName = blok.airport?.name && fromCode ? `${blok.airport.name} [${fromCode}]` : '';
const from: any = ref({
    Name: fromName,
    Code: fromCode,
});
const to: any = ref({
    Name: '',
    Code: '',
});

// Computed variable for the form action. Will update based on selectedLocation.
const formAction = computed(() =>
    from.value.Code && to.value.Code
        ? `/${locale.value}/${t('pages.transfers')}/${t(
              'pages.search-results'
          )}/${from.value?.Code?.toLowerCase()}-${to.value?.Code?.toLowerCase()}`
        : ''
);

// Validation
const validationErrorsCountFrom = ref(0);
const validationErrorsCountTo = ref(0);

const updateLocationFrom = (value) => {
    from.value = value;
    validationErrorsCountFrom.value = 0;
};

const updateLocationTo = (value) => {
    to.value = value;
    validationErrorsCountTo.value = 0;
};

const checkTransfer = () => {
    if (!from.value.Code) {
        validationErrorsCountFrom.value++;
    }
    if (from.value.Code && !to.value.Code) {
        validationErrorsCountTo.value++;
    }
};

// Save to store only after submitting form to search results, for reusing after 'go back' action
// When a user navigates through Nuxt pages, Pinia doesn't update.
const saveToStore = (e) => {
    quoteStore.updateQuoteFormStartDestination({ code: from.value.Code, name: from.value.Name });
    quoteStore.updateQuoteFormEndDestination({ code: to.value.Code, name: to.value.Name });
    e.target.submit();
};

//in case if user open the page and leave it to the next day, the date should be updated
const currentDate = () => {
    if (differenceInDays(today.value, new Date()) > 0) {
        today.value = new Date();
    }

    return today.value;
};

const minToTime = computed(() => {
    const minDate = new Date(new Date(quoteStore.getAllData.fromDate).toDateString());
    const toDate = new Date(new Date(quoteStore.getAllData.toDate).toDateString());
    if (differenceInDays(minDate, toDate) > 0) {
        return '';
    }

    return quoteStore.getAllData.fromTime;
});

onMounted(() => {
    isMounted.value = true;
});
</script>
